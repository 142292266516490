.nav-item a {
    width: 100%;
    display: block;
}

.nav-item {
    margin-left: 30px;
}

.tag{
    background-color: rgb(241, 103, 10);
}

nav {
    width: 100%;
}

.user-profile-sm {
    display: none;
}

.user-profile-lg {
    display: block;
  }
  /* Mobile CSS */
  @media only screen and (max-width: 600px) {
    .user-profile-sm {
        display: block;
    }
    .user-profile-lg {
        display: none;
    }
  }

