
.content-page {
    height:fit-content !important;
}


.user-profile-lg {
    display: block;
  }

.top-bar {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
}

/* PC VIEW */
@media only screen and (min-width: 1053px) {
    .content-page {
        width: 50%;
    }
  }

  /* Tablet */
  @media only screen and (max-width: 1052px) {
    .content-page {
        width: 95%;
    }
  }
  /* Mobile CSS */
  @media only screen and (max-width: 600px) {
    .content-page {
        width: 97%;
    }
    .top-bar {
        margin-left: 0.001rem !important;
        margin-right: 0.001rem !important;
    }
    .shift-tittle{
        font-size: 80%;
        font-weight: 100px;
    }
    .card-body {
        padding: 0.03rem !important;
    }

  }